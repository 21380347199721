<template>
  <div class="fill-height">
    <v-container class="createaccount-page pb-12">
      <v-card max-width="400" class="mx-auto mt-10" elevation="1">
        <v-card-title class="text-center">
          <h1 class="display-1 mx-auto mb-5">{{ $t("Login") }}</h1>
        </v-card-title>

        <v-card-text>
          <v-form v-model="form" ref="form">
            <v-row>
              <connect-button
                v-on:error="oError"
                v-on:success="success"
                class="mb-4 mx-auto"
              ></connect-button>

              <v-col xs="12" cols="12" v-if="o_error && !is_authorizing">
                <v-alert
                  text
                  prominent
                  type="error"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ $t("AccountNotCreated") }}</p>
                  <p class="caption">{{ $t("ErrorMessage") }}: {{ o_error }}</p>
                </v-alert>
              </v-col>

              <v-col xs="12" cols="12" class="text-center">{{
                $t("OrWithEmail")
              }}</v-col>

              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="user.username"
                  :rules="[(v) => !!v || $t('UsernameRequired')]"
                  :disabled="is_authorizing"
                  :label="$t('Username')"
                  required
                ></v-text-field>
              </v-col>
              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="user.password"
                  :rules="[(v) => !!v || $t('PasswordRequired')]"
                  :disabled="is_authorizing"
                  :label="$t('Password')"
                  :append-icon="
                    show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'
                  "
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  required
                ></v-text-field>
              </v-col>
              <v-col xs="12" cols="12">
                <v-btn
                  block
                  :loading="is_authorizing && !fb_authorizing"
                  :disabled="fb_authorizing"
                  color="primary"
                  @click="sendForm"
                  class="text-none"
                  >{{ $t("Button.Send") }}</v-btn
                >
                <div class="mt-3 text-center">
                  {{ $t("NotRegistred") }}
                  <router-link to="/signup">{{
                    $t("CreateAccount")
                  }}</router-link>
                  <br />
                  {{ $t("Or") }}
                  <router-link to="/forgotpassword">{{
                    $t("ForgotPassword")
                  }}</router-link
                  >?
                </div>
              </v-col>
              <v-col xs="12" cols="12" v-if="error && !is_authorizing">
                <v-alert
                  text
                  prominent
                  type="error"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ $t("AccountNotCreated") }}</p>
                  <p class="caption">{{ $t("ErrorMessage") }}: {{ error }}</p>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Login": "Sign in",
    "AccountNotCreated": "Login failed!",
    "Username": "Username",
    "Password": "Password",
    "Hi": "Hi",
    "ErrorMessage": "Error",
    "UsernameRequired": "Username is missing!",
    "PasswordRequired": "Password is missing!",
    "NotRegistred": "Not registred?",
    "CreateAccount" : "Create an account",
    "OrWithEmail": "Or use your e-mail",
    "Or": "or",
    "ForgotPassword": "Forgot Password",
    "PageDescription": "Log in to your diary at Babiry"
    },
    "sv": {
    "Login": "Logga in",
    "AccountNotCreated": "Inloggningen misslyckades!",
    "Username": "Användarnamn",
    "Password": "Lösenord",
    "Hi": "Hej",
    "ErrorMessage": "Fel",
    "UsernameRequired": "Användarnamn saknas!",
    "PasswordRequired": "Lösenord saknas!",
    "NotRegistred": "Inte medlem?",
    "CreateAccount" : "Skapa ett konto",
    "OrWithEmail": "Eller logga in med din email",
    "Or": "eller",
    "ForgotPassword": "Glömt ditt lösenord",
    "PageDescription": "Logga in på din dagbok hos Barndagboken"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import ConnectButton from "../../components/global/ConnectButton.vue";
import { responseHelpers } from "@/_helpers";

export default {
  name: "login",
  components: {
    "connect-button": ConnectButton,
  },
  metaInfo() {
    return {
      title: this.$t("Login"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Login") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    error: null,
    o_error: null,
    form: false,
    show1: false,

    user: {
      password: "",
      username: "",
    },
  }),
  computed: {
    ...mapState({
      is_authorizing: (state) => state.account.status.is_authorizing,
      fb_authorizing: (state) => state.account.status.fb_authorizing,
    }),
  },
  methods: {
    ...mapActions({
      login: "account/login",
    }),
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self
          .login(self.user)
          .then(function (response) {
            self.success(response.data);
          })
          .catch(function (error) {
            self.error = responseHelpers.errorTextFromResponse(error);
            self.o_error = null;
          });
      }
    },
    success(data) {
      var diary = data.user.current_diary;

      var ref = this.$route.query.ref;

      if (ref) {
        this.$router.push(ref);
      } else if (diary && diary.name && diary.id) {
        this.$router.push("/" + diary.name);
      } else {
        this.$router.push("/account");
      }
    },
    oError(err) {
      this.o_error = responseHelpers.errorTextFromResponse(err);
      this.error = null;
    },
  },
};
</script>