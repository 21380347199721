<template>
  <div class="fill-height">
    <v-container class="createaccount-page pb-12">
      <v-card max-width="400" class="mx-auto mt-10" elevation="1">
        <v-card-title class="text-center">
          <h1 class="display-1 mx-auto mb-5">{{ $t("CreateAccount") }}</h1>
        </v-card-title>

        <v-card-text>
          <v-form v-model="form" ref="form" autocomplete="off">
            <v-row>
              <connect-button
                v-on:error="oError"
                v-on:success="success"
                class="mb-4 mx-auto"
              ></connect-button>

              <v-col xs="12" cols="12" v-if="o_error && !is_authorizing">
                <v-alert
                  text
                  prominent
                  type="error"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ $t("AccountNotCreated") }}</p>
                  <p class="caption">{{ $t("ErrorMessage") }}: {{ o_error }}</p>
                </v-alert>
              </v-col>

              <v-col xs="12" cols="12" class="text-center">{{
                $t("OrWithEmail")
              }}</v-col>

              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="user.username"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  :rules="[
                    (v) => !!v || $t('UsernameRequired'),
                    (v) => (v && v.length <= 45) || $t('UsernameMaxLength'),
                    (v) => (v && v.length >= 3) || $t('UsernameToShort'),
                    (v) => /^[a-zA-Z0-9_.]+$/.test(v) || $t('UsernameNotValid'),
                  ]"
                  :disabled="is_authorizing"
                  :label="$t('Username')"
                  required
                ></v-text-field>
              </v-col>
              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="user.email"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  :rules="[
                    (v) => !!v || $t('Validation.EmailRequired'),
                    (v) =>
                      /.+@.+\..+/.test(v) || $t('Validation.EmailNotValid'),
                  ]"
                  :disabled="is_authorizing"
                  :label="$t('Email')"
                  required
                ></v-text-field>
              </v-col>
              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="user.password"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  :rules="[
                    (v) => !!v || $t('PasswordRequired'),
                    (v) => (v && v.length > 8) || $t('PasswordToShort'),
                  ]"
                  :disabled="is_authorizing"
                  :label="$t('Password')"
                  :append-icon="
                    show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'
                  "
                  :type="show1 ? 'text' : 'password'"
                  :hint="$t('MinPasswordLength')"
                  counter
                  @click:append="show1 = !show1"
                  required
                ></v-text-field>
              </v-col>

              <v-col xs="12" cols="12">
                <v-btn
                  block
                  :loading="is_authorizing && !fb_authorizing"
                  :disabled="fb_authorizing"
                  color="primary"
                  @click="sendForm"
                  class="text-none"
                  >{{ $t("Button.Send") }}</v-btn
                >
                <div class="mt-3 text-center">
                  {{ $t("AlreadyAMember") }}
                  <router-link to="/login">{{ $t("Login") }}</router-link>
                </div>
              </v-col>

              <v-col xs="12" cols="12" v-if="error && !is_authorizing">
                <v-alert
                  text
                  prominent
                  type="error"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ error }}</p>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "CreateAccount": "Create account",
    "AccountNotCreated": "Account could not be created!",
    "MinPasswordLength": "At least 8 characters",
    "Username": "Username",
    "Email": "Email",
    "Password": "Password",
    "Hi": "Hi",
    "ErrorMessage": "Error",
    "PasswordRequired": "Password is missing!",
    "OrWithEmail": "Or use your e-mail",
    "AcceptRules": "By completing this form, I agree to the Terms and Privacy Policy.",
    "AlreadyAMember": "Already a member?",
    "Login" : "Sign in",  
    "PasswordToShort": "Password to short. At least 8 characters",
    "UsernameRequired": "Username is missing!",    
    "UsernameMaxLength": "Username must be less than 45 characters",
    "UsernameNotValid": "Username not valid. Use a-zA-Z0-9_.",
    "UsernameToShort": "Username to short. At least 3 characters",
    "PageDescription": "Create your own diary for free on Babiry and start sharing with family and friends."
    },
    "sv": {
    "CreateAccount": "Skapa konto",
    "AccountNotCreated": "Kontot kunde inte skapas!",
    "MinPasswordLength": "Minst 8 tecken",
    "Username": "Användarnamn",
    "Email": "Email",
    "Password": "Lösenord",
    "Hi": "Hej",
    "ErrorMessage": "Fel",
    "PasswordRequired": "Lösenord saknas!",
    "OrWithEmail": "Eller med din email",
    "AcceptRules": "Genom att fylla i detta formulär, godkänner jag villkoren och sekretesspolicyn.",
    "AlreadyAMember": "Redan ett konto?",
    "Login" : "Logga in",
    "PasswordToShort": "Lösenordet för kort. Minst 8 tecken",    
    "UsernameRequired": "Användarnamn saknas!",
    "UsernameMaxLength": "Användarnamnet får inte vara längre än 45 tecken",
    "UsernameNotValid": "Användarnamnet är inte korrekt. Använd a-zA-Z0-9_.",
    "UsernameToShort": "Användarnamnet för kort. Minst 3 tecken",
    "PageDescription": "Skapa en egen dagbok helt gratis på Barndagboken och börja dela med dig till familj och vänner."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import ConnectButton from "../../components/global/ConnectButton.vue";
import { responseHelpers } from "@/_helpers";

export default {
  name: "createaccount",
  components: {
    "connect-button": ConnectButton,
  },
  metaInfo() {
    return {
      title: this.$t("CreateAccount"),
      meta: [
        {
          property: "og:title",
          content: this.$t("CreateAccount") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    error: null,
    o_error: null,
    form: false,
    show1: false,

    user: {
      email: "",
      password: "",
      username: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  computed: {
    ...mapState({
      is_authorizing: (state) => state.account.status.is_authorizing,
      fb_authorizing: (state) => state.account.status.fb_authorizing,
    }),
  },
  methods: {
    ...mapActions({
      register: "account/register",
    }),
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self
          .register(self.user)
          .then(function (response) {
            self.success(response.data);
          })
          .catch(function (error) {
            self.error = responseHelpers.errorTextFromResponse(error);
            self.o_error = null;
          });
      }
    },
    success(data) {
      var diary = data.user.current_diary;
      var ref = this.$route.query.ref;

      if (ref) {
        this.$router.push("/" + diary.name + "?ref=" + ref);
      } else if (diary && diary.name && diary.id) {
        this.$router.push("/" + diary.name);
      } else {
        this.$router.push("/account");
      }
    },
    oError(err) {
      this.o_error = responseHelpers.errorTextFromResponse(err);
      this.error = null;
    },
  },
};
</script>

<style>
.form-row {
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>