<template>
  <div class="fill-height">
    <v-container class="forgottpassword-page pb-12">
      <v-card max-width="400" class="mx-auto mt-10" elevation="1">
        <v-card-title class="text-center">
          <h1 class="display-1 mx-auto mb-5">{{ $t("ForgotPassword") }}</h1>
        </v-card-title>

        <v-card-text>
          <v-form v-model="form" ref="form" autocomplete="off">
            <v-row>
              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="user.email"
                  :rules="[
                    (v) => !!v || $t('Validation.EmailRequired'),
                    (v) =>
                      /.+@.+\..+/.test(v) || $t('Validation.EmailNotValid'),
                  ]"
                  :disabled="is_sending"
                  :label="$t('Email')"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  required
                ></v-text-field>
              </v-col>

              <v-col xs="12" cols="12">
                <v-btn
                  block
                  :loading="is_sending"
                  color="primary"
                  @click="sendForm"
                  class="text-none"
                  >{{ $t("Button.Send") }}</v-btn
                >
                <div class="mt-3 text-center">
                  <router-link to="/login">{{ $t("Login") }}</router-link>
                </div>
              </v-col>

              <v-col xs="12" cols="12" v-if="error">
                <v-alert
                  text
                  prominent
                  type="error"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ error }}</p>
                </v-alert>
              </v-col>
              <v-col xs="12" cols="12" v-if="is_success">
                <v-alert
                  text
                  prominent
                  type="success"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ $t("NewPasswordSent") }}</p>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "ForgotPassword": "Forgot Password",
    "Email": "Email",
    "Login": "Sign in",
    "NewPasswordSent": "A new password was sent to your email.",
    "PageDescription": "If you have forgotten your password to log in to your diary, you can easily order a new one here."
    },
    "sv": {
    "ForgotPassword": "Glömt lösenord",
    "Email": "Email",
    "Login": "Logga in",
    "NewPasswordSent": "Ett nytt lösenord har skickats till din email.",
    "PageDescription": "Har du glömt ditt lösenord för att logga in på din dagbok kan du här lätt beställa ett nytt."
    }
    }
</i18n>

<script>
import { accountService } from "../../_services";

export default {
  name: "forgotpassword",
  metaInfo() {
    return {
      title: this.$t("ForgotPassword"),
      meta: [
        {
          property: "og:title",
          content: this.$t("ForgotPassword") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    error: null,
    is_success: false,
    is_sending: false,
    form: false,

    user: {
      email: "",
    },
  }),
  methods: {
    sendForm() {
      var self = this;
      self.error = null;
      self.is_success = false;

      if (self.$refs.form.validate()) {
        self.is_sending = true;

        accountService
          .forgotPassword(self.user.email)
          .then(function () {
            self.is_sending = false;
            self.is_success = true;
            self.user.email = "";
            self.$refs.form.resetValidation();
          })
          .catch(function (error) {
            self.error = error;
            self.is_sending = false;
          });
      }
    },
  },
};
</script>