<template>
  <v-container class="createaccount-page pb-12">
    <v-card max-width="400" class="mx-auto mt-10" elevation="1">
      <v-card-title class="text-center"
        ><h1 class="display-1 mx-auto mb-5">
          {{ $t("ResetPassword") }}
        </h1></v-card-title
      >

      <v-card-text>
        <v-form v-model="form" ref="form" autocomplete="off">
          <v-row>
            <v-col xs="12" cols="12" class="form-row">
              <v-text-field
                v-model="password"
                :rules="[(v) => !!v || $t('PasswordRequired')]"
                :disabled="is_saving"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                :label="$t('NewPassword')"
                :append-icon="show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                required
              ></v-text-field>
            </v-col>
            <v-col xs="12" cols="12" class="form-row">
              <v-text-field
                v-model="password_repeat"
                :rules="[(v) => !!v || $t('PasswordRequired')]"
                :disabled="is_saving"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                :label="$t('ConfirmPassword')"
                :append-icon="show2 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                required
              ></v-text-field>
            </v-col>
            <v-col xs="12" cols="12">
              <v-btn
                block
                :loading="is_saving"
                color="primary"
                @click="sendForm"
                class="text-none"
                >{{ $t("Button.Send") }}</v-btn
              >
            </v-col>
            <v-col xs="12" cols="12" v-if="error">
              <v-alert text prominent type="error" icon="$vuetify.icons.cloudAlert">
                <p class="caption">{{ error }}</p>
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "ResetPassword": "Reset password",
    "NewPassword": "New password",
    "ConfirmPassword": "Confirm password",
    "PasswordRequired": "Enter password"
    },
    "sv": {
    "ResetPassword": "Återställ lösenord",
    "NewPassword": "Nytt lösenord",
    "ConfirmPassword": "Bekräfta lösenordet",
    "PasswordRequired": "Ange lösenord"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService } from "../../_services";
import { responseHelpers } from "@/_helpers";

export default {
  name: "reset-password",
  metaInfo() {
    return {
      title: this.$t("ResetPassword"),
      meta: [
        {
          property: "og:title",
          content: this.$t("ResetPassword") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
      ],
    };
  },
  data: () => ({
    error: null,
    is_saving: false,
    form: false,
    show1: false,
    show2: false,
    password: "",
    password_repeat: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created: function () {
    var key = this.$route.query.key;
    var userId = this.$route.query.user;

    if (!userId || !key || this.user) this.$router.push("/");
  },
  methods: {
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        var data = {
          password: self.password,
          key: self.$route.query.key,
          user: self.$route.query.user,
        };

        accountService
          .resetPassword(data)
          .then(function () {
            self.$router.push("/login");
          })
          .catch(function (error) {
            self.error = responseHelpers.errorTextFromResponse(error);
            self.is_saving = false;
          });
      }
    },
  },
};
</script>